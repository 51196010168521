/* eslint-disable no-restricted-syntax */
import DeviceApi from '@api/DeviceApi';
import { AppImages } from '@constants/AppImages';
import { DeviceType } from '@constants/Device';
import { TagProfile } from '@constants/Tag';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { useCustomToast } from '@hooks/useToast';
import { DeviceModel } from '@models/Device';
import { StepFormStepsObj } from '@models/FormikTypes';
import { PetModel } from '@models/Pet';
import { TagModel } from '@models/Tag';
import {
  NavigationProp,
  StackActions,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';
import colors from '@styles/colors';
import text from '@styles/text';
import { navigateToPets, navigateToProducts } from '@utils/navigationShortcuts';
import noop from '@utils/noop';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  FlatList,
  Linking,
  ListRenderItem,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { NativeStackScreenProps } from 'react-native-screens/lib/typescript/native-stack';
import SpSentIcon from 'src/components/RoundIcons/SpSentIcon';
import { SpCenter } from 'src/components/SpCenter';
import { SpLoading } from 'src/components/SpLoading';
import { SpSuccessTick } from 'src/components/SpSuccessTick';
import { SpView } from 'src/components/SpView';
import StepFormCreator from 'src/components/StepFormCreator';
import { RootStackParamList } from 'src/pages';
import { navigationRef } from 'src/pages/RootNavigation';
import { closeModal } from 'src/services/ModalBox';
import { PetService } from 'src/services/PetService';
import useBoundStore from 'src/store/store';

import AddPetsContent from './AddPetsContent';
import { FlowStackParamList } from '../..';
import { SpText } from '../../../../components/SpText/SpText';
import { useDevicesByHousehold } from '../../../../hooks/useDevicesByHousehold';
import { DeviceStackParamList } from '../../../Dashboard/Products/Device';
import { DeviceCard } from '../../../Dashboard/components/DeviceHierarchy/DeviceCard';
import LoadingComponent from '../../components/LoadingComponent';
import AssignPet from '../AssignPet';
import FoundPets from '../FoundPets';
import IncompatibleChip from '../IncompatibleChip';
import IndoorModePage from '../IndoorModePage';
import PromptPage from '../PromptPage/PromptPage';

const ASSIGN_PET_DEVICES = [
  DeviceType.CatFlapConnect,
  DeviceType.Cerberus,
  DeviceType.FeederConnect,
  DeviceType.PetDoorConnect,
  DeviceType.FelaquaConnect,
];

type Props = NativeStackScreenProps<FlowStackParamList, 'AddPets'>;

const AddPets = ({ route }: Props) => {
  const {
    existingDevice,
    comingFromSettings,
    comingFromIncompatibleAssignPage,
    petIncompatibleAssociated,
    noProducts,
    deviceId: routeDeviceID,
    loading: routeLoading,
    step: routeStep,
    comingFromProfile,
    deviceType,
    returnToStep,
  } = route.params ?? {};
  const {
    getPetByTagId,
    davidsUpdateTagProfile,
    loadPet,
    petData,
    getDeviceById,
    loadDevice,
    unassignAssignMultiplePetsRequest,
    loadingDevice,
    refreshDeviceTags,
  } = useBoundStore(({ petStore, deviceStore }) => {
    return {
      getPetByTagId: petStore.getPetByTagId,
      loadPet: petStore.loadPet,
      petData: petStore.petData,
      getDeviceById: deviceStore.getDeviceById,
      assignPetRequest: deviceStore.assignPetRequest,
      loadDevice: deviceStore.loadDevice,
      updateTagProfile: deviceStore.updateTagProfile,
      davidsUpdateTagProfile: deviceStore.davidsUpdateTagProfile,
      unassignAssignMultiplePetsRequest: deviceStore.unassignAssignMultiplePetsRequest,
      deviceData: deviceStore.deviceData,
      loadingDevice: deviceStore.loadingDevice,
      refreshDeviceTags: deviceStore.refreshDeviceTags,
    };
  });
  const [step, setStep] = useState(() => {
    if (noProducts) {
      return 0;
    }
    return routeStep || 1;
  });
  const [submitValues, setSubmitValues] = useState<any>();
  const [selectedDevice, setSelectedDevice] = useState<DeviceModel>(null);
  const [assignedPets, setAssignedPets] = useState([]);
  const [incompatiblePets, setIncompatiblePets] = useState([]);
  const [allIncompatiblePetsSorted, setAllIncompatiblePetsSorted] = useState(false);
  const [updatesPetsShared, setUpdatesPetsShared] = useState([]);
  const [indoorModeEnabledPets, setIndoorModeEnabledPets] = useState<PetModel[]>([]);
  const [assignedLoading, setAssignedLoading] = useState(false);
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const flowNavigation = useNavigation<NavigationProp<FlowStackParamList>>();
  const deviceNavigation = useNavigation<NavigationProp<DeviceStackParamList>>();
  const [foundedPetsTagIds, setFoundedPetsTagIds] = useState<number[]>([]);
  const [incompatiblePetAssociated, setIncompatiblePetAssociated] = useState(null);
  const [unnamedPets, setUnnamedPets] = useState(false);
  const [stopSkip, setStopSkip] = useState(false);
  const [newTagAdded, setNewTagAdded] = useState<TagModel>(null);
  const [loading, setLoading] = useState(true);
  const [assignUpdateData, setAssignUpdateData] = useState([]);
  const [tryTimes, setTryTimes] = useState(0);
  const [maxRetriesError, setMaxRetriesError] = useState(false);
  const toast = useCustomToast();
  const { t } = useTranslation();
  const rootNavigation = useNavigation<NavigationProp<RootStackParamList>>();
  const prevStateRef = useRef(0);
  const comeFromRemovePets = useRef<boolean>(false);
  const pets = usePetsByHousehold();
  const devices = useDevicesByHousehold();
  const isFocused = useIsFocused();
  const prevFocusedState = useRef(false);
  const backFromCreatePetProfile = useRef(false);
  const felaquaLearnMode = async deviceId => {
    await DeviceApi.learnMode(deviceId, true);
  };
  const showPetsAddedRef = useRef(false);
  useLayoutEffect(() => {
    if (!isFocused) {
      prevFocusedState.current = isFocused;
      return;
    }
    if (isFocused && prevFocusedState.current === false) {
      prevFocusedState.current = isFocused;
      (async () => {
        await loadDevice(true);
        await loadPet(true);
        const deviceId = selectedDevice?.id ?? routeDeviceID;
        if (deviceId) {
          const device = getDeviceById(deviceId as number);
          if (device) {
            setSelectedDevice(device);
          }
        }
      })();
      setLoading(preloading => routeLoading ?? preloading);
      if (!(backFromCreatePetProfile.current && step === 3 && routeStep === 1)) {
        setStep(routeStep === 0 || routeStep ? routeStep : 1);
      }
      backFromCreatePetProfile.current = false;
    }
  }, [
    getDeviceById,
    isFocused,
    loadDevice,
    loadPet,
    routeDeviceID,
    routeLoading,
    routeStep,
    selectedDevice?.id,
  ]);
  useLayoutEffect(() => {
    if (selectedDevice && selectedDevice?.tags?.length > 0) {
      setStopSkip(false);
      selectedDevice.tags.forEach(tag => {
        const pet = getPetByTagId(tag.id);
        if (pet && !assignedPets.find(assignedPet => assignedPet?.id === pet.id)) {
          setAssignedPets(old => [...old, pet]);
        }
      });
    }
  }, [selectedDevice]);

  // Effect to control the incomapatible tags
  useEffect(() => {
    if (newTagAdded) {
      const incompatibleIsReconciled = incompatiblePets.find(
        (incompPet: any) => incompPet?.tag_id === newTagAdded?.id,
      );
      // If an incompatible tag has been added then we remove it from the tracking
      if (incompatibleIsReconciled) {
        setIncompatiblePets(old => {
          if (old.length > 0) {
            const index = old.findIndex((item: any) => item.id === incompatibleIsReconciled?.id);
            const newData = [...old];
            if (index > -1) {
              newData.splice(index, 1);
            }
            // Check if all incompatible pets are now learnt
            if (newData.length === 0) {
              setAllIncompatiblePetsSorted(true);
            }
            return newData;
          }
          return [];
        });
      }
      setNewTagAdded(null);
    }
  }, [newTagAdded]);

  const petService = useRef(
    new PetService({
      onError: noop,
      timeoutHandler: () => null,
      onFound: async (newTag: TagModel) => {
        setNewTagAdded(newTag);
        await loadPet(true);
        setFoundedPetsTagIds(old => [...old, newTag.id]);
        const pet = getPetByTagId(newTag.id);
        if (pet?.id) {
          setAssignedPets(old => [...old, pet]);
        }
        toast.show({ description: t('pet_found') });
      },
      multipleSearch: !existingDevice,
      maxRetriesHandler: (value: boolean) => setMaxRetriesError(value),
    }),
  );

  const tryAgain = () => {
    if (tryTimes > 2) {
      navigation.navigate('DashboardNavigation');
      Linking.openURL(t('get_help_url'));
    }
    petService.current?.maxRetriesHandler(false);
    setTryTimes(old => ++old);
    setStep(3);
    petService.current?.start(selectedDevice);
  };
  useLayoutEffect(() => {
    setIncompatiblePetAssociated(petIncompatibleAssociated);
    return () => {
      petService.current?.stop(selectedDevice as DeviceModel);
    };
  }, []);

  const handleSelectedDevice = (device: DeviceModel) => () => {
    // TODO if device type = CDB, go to CreatePetProfile flow
    if (device?.product_id === DeviceType.Cerberus && device?.tags.length === 0) {
      const params = existingDevice
        ? {
            device_id: device.id,
            nextPage: {
              stack: 'HouseholdNavigation',
              screen: 'Household',
            },
          }
        : { device_id: device.id };

      flowNavigation.dispatch(StackActions.replace('CreatePetProfile', params));
    } else if (device?.product_id !== DeviceType.Cerberus) {
      setSelectedDevice(device);
      setStep(prevStep => prevStep + 1);
    }
  };

  const renderItem: ListRenderItem<DeviceModel> = ({ item }) => {
    if (item?.product_id === DeviceType.Hub) {
      return null;
    }
    return item?.product_id === DeviceType.Cerberus && item?.tags?.length > 0 ? (
      <SpView
        {...testProperties('Cards', 'view')}
        style={(styles.devicesContainer, styles.container, styles.itemDisable)}
      >
        <DeviceCard
          data={item}
          isPetFlow
        />
      </SpView>
    ) : (
      <TouchableOpacity
        onPress={handleSelectedDevice(item)}
        style={(styles.devicesContainer, styles.container)}
      >
        <DeviceCard
          data={item}
          isPetFlow
        />
      </TouchableOpacity>
    );
  };

  const onSubmitIndoor = useCallback(async () => {
    try {
      assignedPets.forEach(async pet => {
        const petIsIndoorMode = indoorModeEnabledPets.find(p => p.id === pet.id);
        if (pet.profile === TagProfile.Safe && !petIsIndoorMode) {
          await davidsUpdateTagProfile(selectedDevice?.id, pet, TagProfile.Permitted);
        }
        if (pet.profile !== TagProfile.Safe && petIsIndoorMode) {
          await davidsUpdateTagProfile(selectedDevice?.id, pet, TagProfile.Safe);
        }
      });
    } catch (err) {}
  }, [indoorModeEnabledPets]);

  const selectDevice = useMemo(() => {
    if (!devices) return <SpLoading />;
    return (
      <SpView>
        <SpView>
          <SpText
            {...testProperties('ChooseProduct', 'text')}
            accessible={false}
            style={[text.title, styles.titlePadding]}
          >
            {t('choose_a_product')}
          </SpText>
        </SpView>
        <SpView style={styles.listContainer}>
          <FlatList
            data={devices}
            renderItem={renderItem}
            keyExtractor={item => `${item.id}`}
            {...testProperties('flatList', 'items')}
            accessible={false}
          />
        </SpView>
      </SpView>
    );
  }, [devices]);

  useEffect(() => {
    if (step === 1) {
      const assignPetDevices = devices.filter(elem => ASSIGN_PET_DEVICES.includes(elem.product_id));
      if (assignPetDevices.length === 1) {
        handleSelectedDevice(assignPetDevices[0])();
      }
    }
  }, [step, devices]);

  const NoProducts = useCallback(
    () => (
      <SpView>
        <SpText style={[text.title, styles.noProducts]}>{t('no_products_in_household')}</SpText>
        <SpText style={[text.defaultText, styles.noProductsDescription]}>
          {t('no_products_in_household_description')}
        </SpText>
      </SpView>
    ),
    [t],
  );
  const checkToLeavePetFlow = (): boolean => {
    if (deviceType === DeviceType.Cerberus) {
      flowNavigation.navigate('AddDogBowl', {
        step: returnToStep,
      });
      return false;
    }
    if (!(comingFromProfile && existingDevice && pets.length > 1)) {
      if (deviceType === DeviceType.FelaquaConnect) {
        flowNavigation.navigate('AddFelaqua', {
          step: returnToStep,
          deviceId: routeDeviceID as DeviceModel['id'],
        });
        return false;
      }
      if (deviceType === DeviceType.FeederConnect) {
        flowNavigation.navigate('AddFeeder', {
          step: returnToStep,
          deviceId: routeDeviceID as DeviceModel['id'],
        });
        return false;
      }
    }
    navigateToProducts();
    return false;
  };

  const AddPetsLoading = useMemo(() => {
    let message: string;

    if (foundedPetsTagIds.length < 1) {
      message = t('looking_for_pet');
    } else if (existingDevice || comingFromSettings || comingFromIncompatibleAssignPage) {
      message = t('found_pet');
      petService.current?.stop(selectDevice as DeviceModel);
    } else {
      message = t('pets_found') + foundedPetsTagIds.length;
    }

    return (
      <View style={[styles.loaderWrap]}>
        {maxRetriesError ? (
          <SpView style={styles.loaderContainer}>
            <LoadingComponent
              isConnected
              connectedMsg={t('timeout_error')}
              error
            />
          </SpView>
        ) : (
          <SpCenter>
            {(foundedPetsTagIds.length < 1 ||
              (!existingDevice && foundedPetsTagIds.length > 0) ||
              (!existingDevice &&
                !comingFromSettings &&
                !comingFromIncompatibleAssignPage &&
                incompatiblePets.length > 0)) && (
              <SpView
                {...testProperties('loading element', 'container')}
                accessible={false}
              >
                <LoadingComponent
                  searchMsg={message}
                  direction="row"
                  type="insideCircle"
                />
                {!existingDevice && !comingFromSettings && !comingFromIncompatibleAssignPage && (
                  <SpCenter>
                    <SpText
                      style={[styles.link]}
                      onPress={checkToLeavePetFlow}
                    >
                      {t('do_it_later')}
                    </SpText>
                  </SpCenter>
                )}
              </SpView>
            )}
            {(existingDevice || comingFromSettings || comingFromIncompatibleAssignPage) &&
              foundedPetsTagIds.length === 1 && (
                <SpView
                  style={styles.justify}
                  accessible={false}
                >
                  <SpSentIcon />
                  <SpText {...testProperties('found pets', 'text')}>{message}</SpText>
                </SpView>
              )}
          </SpCenter>
        )}
      </View>
    );
  }, [
    foundedPetsTagIds,
    existingDevice,
    maxRetriesError,
    allIncompatiblePetsSorted,
    incompatiblePets,
    t,
  ]);
  useLayoutEffect(() => {
    if (selectedDevice) {
      const shouldWeJumpFoundPage =
        !stopSkip && selectedDevice?.tags?.length > 0 && !existingDevice;
      const shouldWeJumpAssignPage =
        !stopSkip && pets?.length > 0 && !existingDevice && prevStateRef.current !== 3;
      const morePetMsgExistingFeeder = !(
        selectedDevice?.tags?.length > 0 &&
        existingDevice &&
        DeviceType.FeederConnect === selectedDevice?.product_id
      );

      const morePetMsgNewFeeder =
        assignedPets.length > 1 && DeviceType.FeederConnect === selectedDevice?.product_id;
      if (step === 2 && morePetMsgExistingFeeder) {
        setStep(3);
      } else if (step === 3 && shouldWeJumpFoundPage) {
        setStep(4);
      } else if (step === 3 && shouldWeJumpAssignPage) {
        setStep(6);
      } else if (step === 3) {
        petService.current?.start(selectedDevice);
      } else if (step === 7 && !incompatiblePetAssociated) {
        setStep(old => ++old);
      } else if (step === 8 && (existingDevice || !morePetMsgNewFeeder)) {
        if (selectedDevice?.product_id === DeviceType.CatFlapConnect) {
          showPetsAddedRef.current = true;
        }
        setStep(10);
      } else if (petService) {
        petService.current?.stop(selectedDevice as DeviceModel);
      }
      if (!(step === 3 && shouldWeJumpAssignPage)) setLoading(false);
      prevStateRef.current = step;
    }
  }, [step, selectedDevice, stopSkip, existingDevice, assignedPets]);

  useEffect(() => {
    (async () => {
      if (step === 4) {
        const newPetData = [];
        await loadPet(true);
        assignedPets.map(assignedPet => {
          const newPet = petData.find(pet => assignedPet.id === pet.id) || null;
          if (newPet) {
            newPetData.push(newPet);
          }
        });
        setAssignedPets(newPetData);
      }
    })();
  }, [step, selectedDevice, foundedPetsTagIds]);
  useLayoutEffect(() => {
    if (step === 6) {
      setLoading(true);
      refreshDeviceTags(selectedDevice.id);
      const updatedPets = pets.map(pet => {
        return {
          pet,
          tag: (getDeviceById(selectedDevice.id)?.tags || []).find(
            item => item?.id === pet?.tag_id,
          ),
          isAssigned: (getDeviceById(selectedDevice.id)?.tags || []).some(
            tag => tag?.id === pet?.tag_id,
          ),
        };
      });

      if (!comeFromRemovePets.current) {
        setUpdatesPetsShared(updatedPets);
      }

      // If all the pets are already assigned to the device we don't need to go to the assign screen
      const skipStep = !updatedPets.some(pet => !pet.isAssigned);
      if (skipStep && !comeFromRemovePets.current) {
        setStep(old => ++old);
      }
      setLoading(false);
      comeFromRemovePets.current = false;
    }
  }, [getDeviceById, pets, refreshDeviceTags, selectedDevice, step]);

  const redirectToAddPet = useCallback(
    (pet: PetModel) => {
      setStep(7);
      setIncompatiblePetAssociated(pet?.id);
    },
    [],
  );
  const step3 = useMemo((): StepFormStepsObj => {
    if (loading) {
      return {
        ui: LoadingComponent,
        hideButton: true,
        hideProgressHeader: true,
      };
    }

    if (selectedDevice.product_id === DeviceType.FelaquaConnect) {
      felaquaLearnMode(selectedDevice.id);
    }

    return maxRetriesError
      ? {
          ui: (
            <AddPetsContent
              productId={deviceType || selectedDevice?.product_id}
              hideCustomHeader
              handler={null}
              loadingElement={AddPetsLoading}
              withStepNums={false}
            />
          ),
          backBottomButton: true,
          hideButton: false,
          customHeaderProps: {
            withLeaveButton: true,
            rightButtonText: t('get_help'),
            withRightButton: true,
            leaveButtonAction: () => {
              petService.current?.stop(selectedDevice as DeviceModel);
              navigation.navigate('DashboardNavigation', {
                screen: 'Pets',
              });
            },
          },
          buttonText: tryTimes > 2 ? t('get_help') : t('sorry_try_again'),
          forcePressHandler: tryAgain,
        }
      : {
          ui: (
            <AddPetsContent
              productId={deviceType || selectedDevice?.product_id}
              hideCustomHeader
              handler={null}
              loadingElement={AddPetsLoading}
              withStepNums={false}
            />
          ),
          customHeaderProps: {
            withLeaveButton: !loading,
            withArrowBack: !loading,
            leaveButtonAction: () => {
              petService.current?.stop(selectedDevice as DeviceModel);
              navigation.navigate('DashboardNavigation', {
                screen: 'Pets',
              });
            },
          },
          hideButton: foundedPetsTagIds.length < 1 || loading,
          interceptor: () => {
            petService.current?.stop(selectedDevice as DeviceModel);
            setIncompatiblePetAssociated(null);
            // Checking if an existing Device, so we diverge to a different flow
            if (comingFromSettings) {
              flowNavigation.dispatch(
                StackActions.push('DeviceNavigation', {
                  screen: 'DeviceSetting',
                  params: {
                    id: selectedDevice.id,
                  },
                }),
              );
              return false;
            }
            if (comingFromIncompatibleAssignPage) {
              setStep(6);
              return false;
            }
            if (
              existingDevice ||
              (foundedPetsTagIds.length === 1 && assignedPets.length === 0 && pets.length === 0)
            ) {
              backFromCreatePetProfile.current = true;
              const newPet = getPetByTagId(foundedPetsTagIds[0]);
              flowNavigation.navigate('CreatePetProfile', {
                cdb: false,
                id: newPet.id,
                existingDevice,
                foundJustOnePet: !existingDevice,
                deviceType:
                  foundedPetsTagIds.length === 1 && assignedPets.length === 0
                    ? selectedDevice.product_id
                    : null,
              });
              return false;
            }
            return true;
          },
          hideProgressHeader: true,
        };
  }, [maxRetriesError, loading, petService, tryTimes, foundedPetsTagIds, selectedDevice]);

  return (
    <StepFormCreator
      step={step}
      setStep={setStep}
      headerTitle={t('add_pet')}
      submitValues={submitValues}
      setSubmitValues={setSubmitValues}
      disableKeyboardAwoiding={step === 1}
      deviceType={deviceType || selectedDevice?.product_id}
      enableBackSwipeHandler={true}
      enableIOSBackSwipeActions={true}
      steps={{
        0: {
          ui: NoProducts,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToPets,
          },
          buttonText: t('add_a_product'),
          interceptor: () => {
            flowNavigation.navigate('SelectProduct');
            return true;
          },
          hideProgressHeader: true,
        },
        1: {
          ui: selectDevice,
          hideButton: true,
          skipStep: Boolean(selectedDevice),
          hideProgressHeader: true,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToProducts,
          },
        },
        2: {
          ui: loading ? (
            LoadingComponent
          ) : (
            <PromptPage
              headerMessage={{
                txt: selectedDevice?.name || selectedDevice?.serial_number,
                img: AppImages.feeder,
              }}
              title={t('more_pet_title_exiting_device')}
              subtitle={t('more_pet_subtitle')}
              showButton={false}
            />
          ),
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToPets,
            withTitle: false,
            withArrowBack: false,
            withRightButton: false,
          },
          goBackAction: () => setStep(prevStep => prevStep - 1),
          hideProgressHeader: true,
        },
        3: step3,
        4: {
          ui: (
            <FoundPets
              isRemovable
              addAnotherPetAction={() => {
                setStep(3);
                setFoundedPetsTagIds([]);
                setStopSkip(true);
              }}
              assignedPets={assignedPets}
              continueButtonAction={() => {
                return setStep(prevStep => prevStep + 1);
              }}
              setAssignedPets={setAssignedPets}
              setUnnamedPets={setUnnamedPets}
            />
          ),
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToPets,
          },
          hideProgressHeader: false,
          skipStep: existingDevice,
          handleAndroidBackPress: () => {
            const routeName = navigationRef.current.getCurrentRoute()?.name;
            if (routeName === 'PetEditBio') {
              navigationRef.goBack();
              return true;
            }
            if (routeName === 'PetProfileSettings') {
              navigation.navigate('FlowNavigation', {
                screen: 'AddPets',
                params: {
                  deviceType: selectedDevice.product_id,
                  deviceId: selectedDevice.id,
                  step: 4,
                },
              });
              return true;
            }
            if (routeName === 'AddPets') {
              navigateToPets();
              return true;
            }
            return false;
          },
        },
        5: {
          ui: loading ? (
            LoadingComponent
          ) : (
            <PromptPage
              title={t('you_havent_named_all_your_pets')}
              subtitle={t('you_can_continue_without_naming')}
              showButton={false}
            />
          ),
          skipStep: unnamedPets === false || existingDevice,
          additionalButton: true,
          additionalButtonName: t('go_back'),
          additionalButtonAction: () => setStep(prevStep => prevStep - 1),
          customHeaderProps: {
            withLeaveButton: false,
            withTitle: false,
            withArrowBack: false,
            withRightButton: false,
          },
          goBackAction: () => setStep(prevStep => prevStep - 1),
          hideProgressHeader: true,
          forcePressHandler: () => setStep(10),
        },
        6: {
          ui:
            loading || loadingDevice ? (
              LoadingComponent
            ) : (
              <AssignPet
                isAsync={false}
                device={selectedDevice}
                data={updatesPetsShared}
                buttonText={t('add_another_pet')}
                shouldPassData
                setAssignUpdateData={setAssignUpdateData}
                title={t('assign_pet_title')}
                subtitle={(selectedDevice?.product_id === DeviceType.Cerberus) ? t('assign_pet_subtitle_cdb') : t('assign_pet_subtitle')}
                isSetupFlow
                incompatiblePets={incompatiblePets}
                setIncompatiblePets={setIncompatiblePets}
                onClickOnIncompatiblePet={redirectToAddPet}
                saveButtonAction={() => {
                  if (selectedDevice?.product_id === DeviceType.Cerberus) {
                    flowNavigation.dispatch(
                      StackActions.replace('CreatePetProfile', {
                        cdb: true,
                        device_id: routeDeviceID,
                      }),
                    );
                  } else {
                    setStopSkip(true);
                    setStep(3);
                  }
                }}
              />
            ),
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToPets,
          },
          interceptor: async () => {
            const differences = assignUpdateData.filter(({ pet, isAssigned }, ind) => {
              return (
                pet.id === updatesPetsShared[ind].pet.id &&
                isAssigned !== updatesPetsShared[ind].isAssigned
              );
            });
            setLoading(true);
            const result = await unassignAssignMultiplePetsRequest(
              selectedDevice.id,
              differences.map(elem => ({
                assign: elem.isAssigned,
                tagId: elem.pet.tag_id,
                pet: elem.pet,
              })),
            );
            if (result) {
              // Updating AssignedPets
              const newAssignedPets = assignUpdateData
                .filter(petAssigned => petAssigned.isAssigned)
                .map(petInfo => petInfo.pet);
              setAssignedPets(newAssignedPets);
              setUpdatesPetsShared(
                assignUpdateData.map(updatePetData => {
                  return {
                    pet: updatePetData.pet,
                    tag: updatePetData.tag,
                    isAssigned: updatePetData.isAssigned,
                  };
                }),
              );
              setLoading(false);
              if (!newAssignedPets.length) {
                checkToLeavePetFlow();
                return false;
              }
              return true;
            }
          },
          hideProgressHeader: true,
          buttonDisabled: loading,
        },
        7: {
          ui: loading ? (
            LoadingComponent
          ) : (
            <IncompatibleChip
              productType={selectedDevice?.product_id}
              incompatiblePetAssociated={incompatiblePetAssociated}
              buttonAction={() => {
                setStep(3);
                setFoundedPetsTagIds([]);
                setStopSkip(true);
              }}
            />
          ),
          customHeaderProps: {
            withArrowBack: true,
            withLeaveButton: false,
            withTitle: false,
            withRightButton: false,
          },
          hideButton: true,
          // skipStep: !incompatiblePetAssociated,
        },
        8: {
          ui: loading ? (
            LoadingComponent
          ) : (
            <PromptPage
              title={t('more_pet_title')}
              subtitle={t('more_pet_subtitle')}
              buttonTitle={t('unassign_pets')}
              buttonAction={() => {
                comeFromRemovePets.current = true;
                setLoading(true);
                setStep(6);
              }}
            />
          ),
          hideProgressHeader: true,
          customHeaderProps: {
            withLeaveButton: true,
            leaveButtonAction: navigateToProducts,
          },
          handleAndroidBackPress: () => {
            comeFromRemovePets.current = true;
            setLoading(true);
            setStep(6);
            return true;
          },
        },
        9: {
          ui: (
            <IndoorModePage
              petList={
                existingDevice ? [getPetByTagId(foundedPetsTagIds[0]) as PetModel] : assignedPets
              }
              indoorModeEnabledPets={indoorModeEnabledPets}
              setIndoorModeEnabledPets={setIndoorModeEnabledPets}
              continueButtonAction={() => closeModal('IndoorModeModal')}
            />
          ),
          customHeaderProps: existingDevice
            ? null
            : {
                withArrowBack: true,
                withLeaveButton: false,
                withTitle: false,
                withRightButton: false,
              },
          interceptor: async () => {
            await onSubmitIndoor();
            return true;
          },
          skipStep: selectedDevice?.product_id !== DeviceType.CatFlapConnect,
        },
        10: {
          ui: (
            <View style={{ marginTop: 112 }}>
              {assignedLoading ? (
                <SpView style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <LoadingComponent />
                </SpView>
              ) : (
                <SpSuccessTick
                  text={(() => {
                    return t(
                      selectedDevice?.product_id === DeviceType.FeederConnect ||
                        selectedDevice?.product_id === DeviceType.FelaquaConnect ||
                        selectedDevice?.product_id === DeviceType.PetDoorConnect ||
                        selectedDevice?.product_id === DeviceType.Cerberus ||
                        (selectedDevice?.product_id === DeviceType.CatFlapConnect && existingDevice) ||
                        (selectedDevice?.product_id === DeviceType.CatFlapConnect && showPetsAddedRef.current)
                        ? existingDevice ||
                          incompatiblePets.length > 0 ||
                          selectedDevice?.product_id === DeviceType.Cerberus
                          ? 'pet_added'
                          : 'pets_added'
                        : 'setup_complete',
                    );
                  })()}
                />
              )}
            </View>
          ),
          hideProgressHeader: true,
          hideCustomHeader: true,
          hideButton: assignedLoading,
          interceptor: () => {
            if (selectedDevice?.product_id === DeviceType.CatFlapConnect && showPetsAddedRef.current) {
              showPetsAddedRef.current = false;
              setStep(9);
              return false;
            }
            checkToLeavePetFlow();
            return false;
          },
          handleAndroidBackPress: () => {
            navigateToPets();
            return true;
          },
        },
      }}
    />
  );
};

export default AddPets;

const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  devicesContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: colors.greyBorder.color,
    borderWidth: 1,
    borderRadius: 14,
    marginBottom: 25,
  },
  titlePadding: {
    paddingTop: 20,
    paddingBottom: 30,
  },
  listContainer: {
    paddingBottom: 100,
    flex: 1,
  },
  loaderWrap: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  noProducts: {
    marginTop: 24,
  },
  noProductsDescription: {
    textAlign: 'center',
    marginTop: 24,
  },
  link: {
    textDecorationLine: 'underline',
    marginLeft: 50,
  },
  justify: {
    flexDirection: 'row',
    gap: 12,
    alignItems: 'center',
    marginTop: 20,
    marginBottom: windowHeight < 810 ? 95 : 60,
  },
  loaderContainer: {
    alignItems: 'center',
    marginTop: 20,
    marginBottom: windowHeight < 810 ? 95 : 70,
  },
  itemDisable: {
    opacity: 0.5,
  },
});
