import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React from 'react';
import {
  ButtonProps,
  Platform,
  Pressable,
  StyleProp,
  Text,
  TextProps,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import LoadingSpinner from './Loader/Loader';
import LoaderCircleLine from './LoaderCircleLine';
import { SpHStack } from './SpHStack';

interface SpRoundedHeaderButtonProps extends ButtonProps {
  disabled?: boolean;
  backgroundColor?: string;
  h?: number;
  w?: number;
  textStyles?: StyleProp<TextStyle>;
  borderRadius?: number;
  paddingHorizontal?: number;
  isLoading?: boolean;
  stylesForContainer?: StyleProp<ViewStyle>;
  stylesForContent?: StyleProp<ViewStyle>;
  iconSVG?: React.ReactNode;
  textProps?: Omit<TextProps, 'style'>;
  /**
   * Indicates what the loading component would be used, by Default for Android it is 'spinner' <<LoaderCircleLine>> and for iOS it is 'circle' <<LoadingSpinner>>
   */
  loadingIconType?: 'circle' | 'spinner';
}

export const SpRoundedHeaderButton = ({
  title,
  disabled = false,
  backgroundColor = '#a1a1a1',
  h = 40,
  textStyles,
  borderRadius = 14,
  paddingHorizontal = 20,
  isLoading = false,
  stylesForContainer = {},
  stylesForContent = {},
  w,
  iconSVG,
  textProps,
  loadingIconType = Platform.OS === 'ios' ? 'circle' : 'spinner',
  ...props
}: SpRoundedHeaderButtonProps) => (
  <Pressable
    disabled={disabled}
    style={[stylesForContainer, disabled && !isLoading ? { opacity: 0.6 } : null]}
    {...testProperties(title, 'rounded button', 'Button')}
    {...props}
  >
    <View
      {...testProperties(title, 'rounded button text wrapper')}
      style={[
        {
          justifyContent: 'center',
          alignItems: 'center',
          height: h,
          backgroundColor,
          borderRadius,
          paddingHorizontal,
        },
        stylesForContent,
        w && { width: w },
      ]}
    >
      {!isLoading ? (
        <SpHStack
          {...testProperties(title, 'rounded button text stack')}
          alignItems="center"
          justifyContent="space-between"
          space={4}
        >
          {iconSVG ?? iconSVG}
          <Text
            {...testProperties(title, 'rounded button text')}
            style={
              textStyles || {
                color: colors.white.color,
                fontSize: 20,
                fontFamily: 'Rubik_Medium',
              }
            }
            {...textProps}
          >
            {title}
          </Text>
        </SpHStack>
      ) : loadingIconType === 'spinner' ? (
        <LoadingSpinner color={colors.white.color} />
      ) : (
        <LoaderCircleLine
          backgroundColor={backgroundColor}
          color={colors.white.color}
        />
      )}
    </View>
  </Pressable>
);
