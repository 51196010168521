import { DayWeekMonthShortFormat } from '@constants/DateFormat';
import useTour from '@hooks/useTour';
import { useViewDays } from '@hooks/useViewDays';
import { DeviceModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import {
  GroupReportPointModel,
  MappedConsumptionReportDataModel,
  ReportChangeViewDayPayload,
} from '@models/ReportModel';
import { useIsFocused } from '@react-navigation/native';
import { fillEmptyPoints } from '@utils/fillEmptyPoints';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleSheet, View } from 'react-native';
import { SpText } from '../../../../../components/SpText/SpText';
import { SpView } from 'src/components/SpView';
import { VisitsLineChart } from 'src/components/VisitsLineChart';
import DateNavigation from 'src/pages/Dashboard/components/DateNavigation';
import { FeedingEventReport } from 'src/pages/Dashboard/components/FeedingEventReport';
import { TimeService } from 'src/services/TimeService';

import { LineGraphModalWrapper } from './components/LineGraphModalWrapper';
import { PetAverageStatsFeeds } from './components/PetAverageStatsFeeds';
import LoadingSpinner from '../../../../../components/Loader/Loader';
import useBoundStore from '../../../../../store/store';
import FeedingVisitsSlide from '../../../../Tour/components/FeedingVisitsSlide/FeedingVisitsSlide';
import { ReportRangeLastName, ReportRangeType } from '../../../constants/ReportRangeType';
import { useCurrentAndPreviousDays } from '../../../hooks/useCurrentAndPreviousDays';
import { useGroupedConsumptionStats } from '../../../hooks/useGroupedConsumptionStats';

interface PetDashboardFeedingProps {
  data: GroupReportPointModel<MappedConsumptionReportDataModel>[];
  pet: PetModel;
  devices: DeviceModel[];
  onChangeViewedDays: (data: ReportChangeViewDayPayload) => void;
  rangeType: ReportRangeType;
  currentDate: number;
}

export const PetDashboardFeeding = ({
  data,
  pet,
  devices,
  onChangeViewedDays,
  rangeType,
  currentDate,
}: PetDashboardFeedingProps) => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState(1);
  const isFocused = useIsFocused();
  const { loading } = useBoundStore(s => s.reportStore);
  const viewedDays = useViewDays(rangeType);

  const initTour = useTour({
    uniqueId: 'v0-graph-feeding-dashboard',
    components: [<FeedingVisitsSlide />],
    devices: ['all'],
  });
  useLayoutEffect(() => {
    initTour();
  }, [isFocused]);

  const activityDetails = useCurrentAndPreviousDays<MappedConsumptionReportDataModel>(
    currentDate,
    data,
    viewedDays,
  );

  const convertedActivityDetails = useMemo(
    () => fillEmptyPoints(activityDetails.rangeData, viewedDays, currentDate),
    [activityDetails, viewedDays, currentDate],
  );

  const stats = useGroupedConsumptionStats(
    data,
    pet,
    devices,
    activityDetails?.currentDay?.date,
    false,
  );

  const pointDetailsRenderItem = useCallback(
    ($event: { item: MappedConsumptionReportDataModel }) => {
      return <FeedingEventReport data={$event.item} />;
    },
    [],
  );

  const renderItem = useCallback(
    ({
      item,
      index,
    }: {
      item: GroupReportPointModel<MappedConsumptionReportDataModel>;
      index: number;
    }) => {
      if (!item) {
        return;
      }
      const isActive = index + 1 === activeItem;
      const isVisibleLeftTitle = () => {
        if (viewedDays === ReportRangeType.SevenDays) {
          return TimeService.toLocal(item?.date).toFormat(DayWeekMonthShortFormat);
        }
        if (viewedDays === ReportRangeType.TwentyEightDays) {
          if (index === 0 || isActive || index % 7 === 0) {
            return TimeService.toLocal(item?.date).toFormat(DayWeekMonthShortFormat);
          }
          return null;
        }
      };
      return (
        <VisitsLineChart
          isActive={!!(item.points.length && isActive)}
          setActive={() => {
            item.points.length && setActiveItem(index + 1);
          }}
          lowState={viewedDays === ReportRangeType.TwentyEightDays}
          leftTitle={isVisibleLeftTitle()}
          rightTitle={`${item?.points.length} ${t('visits').toLowerCase()}`}
          points={item.points}
          pointDetailsRenderItem={pointDetailsRenderItem}
        />
      );
    },
    [activeItem, viewedDays, pointDetailsRenderItem],
  );

  const ListHeader = useCallback(
    () => (
      <View>
        {convertedActivityDetails.length > 0 &&
          convertedActivityDetails?.[0]?.date === TimeService.toLocal().endOf('day').toMillis() && (
            <SpText
              style={{ marginBottom: -20 }}
              fontFamily="Rubik_Medium">
              {t('today')}
            </SpText>
          )}
      </View>
    ),
    [convertedActivityDetails],
  );
  const dateNavigationData = {
    name: t(
      ReportRangeLastName[
        viewedDays === ReportRangeType.TwentyEightDays
          ? ReportRangeType.TwentyEightDays
          : ReportRangeType.SevenDays
      ],
      {
        value:
          viewedDays === ReportRangeType.TwentyEightDays
            ? ReportRangeType.TwentyEightDays
            : ReportRangeType.SevenDays,
      },
    ),
    viewedDays,
  };
  return (
    <>
      {stats ? (
        <LineGraphModalWrapper
          data={stats}
          modal="LineGraph"
        />
      ) : null}
      <View style={styles.container}>
        <DateNavigation
          title={dateNavigationData.name}
          currentDate={TimeService.parseDate(currentDate).toJSDate()}
          setDate={date => {
            onChangeViewedDays({ date });
          }}
          viewedDays={viewedDays - 1}
          setViewedDays={(days: number) => {
            onChangeViewedDays({ days, date: TimeService.endOfDay().toMillis() });
            setActiveItem(days === ReportRangeType.TwentyEightDays ? null : 1);
          }}
          withButtons
        />
        {loading ? (
          <SpView style={styles.spinner}>
            <LoadingSpinner />
          </SpView>
        ) : (
          <>
            <SpView style={styles.averageContainer}>
              <SpView style={styles.averageWrap}>
                <PetAverageStatsFeeds data={convertedActivityDetails} />
              </SpView>
            </SpView>
            <SpView style={styles.flatlistContainer}>
              <FlatList
                style={styles.flatlist}
                data={convertedActivityDetails}
                renderItem={renderItem}
                ListHeaderComponent={ListHeader}
                keyExtractor={item => item?.date.toString()}
              />
            </SpView>
          </>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    flex: 1,
  },
  flatlist: { marginHorizontal: -24, paddingHorizontal: 24 },
  flatlistContainer: {
    flex: 1,
    paddingTop: 56,
    marginHorizontal: -24,
    paddingHorizontal: 24,
    backgroundColor: 'rgba(38, 58, 67, 0.06)',
  },
  averageContainer: {
    marginTop: 8,
    position: 'relative',
    height: 48,
    zIndex: 9999,
  },
  averageWrap: {
    paddingHorizontal: 8,
    width: '100%',
    position: 'absolute',
    justifyContent: 'space-between',
  },
  spinner: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
  },
});
