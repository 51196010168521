import { SafeAreaView, StyleSheet, View } from 'react-native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SpRoundedHeaderButton } from 'src/components/SpRoundedHeaderButton';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import SpHouseholdsSelect from 'src/components/SpHouseholdsSelect';
import colors from '@styles/colors';
import { useTranslation } from 'react-i18next';
import { testProperties } from '@utils/testProperties';
import atoms from '@styles/atoms';
import { SpView } from 'src/components/SpView';
import { HouseholdStackParamList } from '.';
import House from '../../../assets/House';
import { BackHandler } from 'react-native';
import { navigateToPets } from '@utils/navigationShortcuts';

const ChooseSetup = () => {
  const [activeOption, setActiveOption] = useState(null);
  const navigation = useNavigation<NavigationProp<HouseholdStackParamList>>();
  const { t } = useTranslation();

  const options = useMemo(
    () => [
      { id: 1, title: t('create_household_title') },
      { id: 2, title: t('join_household_title') },
    ],
    [],
  );
  const onContinuePress = useCallback(() => {
    if (activeOption === 1) navigation.navigate('CreateHousehold');
    if (activeOption === 2) navigation.navigate('JoinHousehold', { userHasNoHouseholds: true });
  }, [activeOption]);

  const handleOption = (optionId: number) => {
    setActiveOption(optionId);
  };

  const handleAndroidBackPress = useCallback(() => {
    navigation.goBack();
    return true;
  }, []);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', handleAndroidBackPress);

    return () => {
      backHandler.remove();
    };
  }, [handleAndroidBackPress]);

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.inner}>
        <SpHouseholdsSelect
          icon={<House />}
          text={t('create_household_text')}
          mainCard
          isActive={options[0].id === activeOption}
          onPress={handleOption}
          option={options[0]}
        />

        <SpHouseholdsSelect
          text={t('join_household_text')}
          marginTop={40}
          isActive={options[1].id === activeOption}
          onPress={handleOption}
          option={options[1]}
        />
      </View>

      <View style={atoms.continueButton}>
        <SpRoundedHeaderButton
          title={t('continue')}
          disabled={!activeOption}
          onPress={onContinuePress}
          backgroundColor={colors.primary.color}
          h={56}
          stylesForContainer={{ marginBottom: 80 }}
        />
      </View>
    </SafeAreaView>
  );
};

export default ChooseSetup;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: colors.white.color },
  inner: { alignItems: 'center', paddingTop: 86, paddingHorizontal: 19 },
  imageWrapper: {
    justifySelf: 'center',
    height: 70,
    width: 100,
    borderWidth: 1,
    borderColor: 'grey',
    borderRadius: 10,
    overflow: 'hidden',
  },
  image: {
    height: 40,
    width: 40,
  },
  buttonWrap: {
    marginVertical: 20,
    paddingHorizontal: 40,
    width: '100%',
  },
  bottomButtonsContainer: {
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
    paddingHorizontal: 24,
  },
});
