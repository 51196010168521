import { DayWeekMonthShortFormat } from '@constants/DateFormat';
import useTour from '@hooks/useTour';
import { useViewDays } from '@hooks/useViewDays';
import { DeviceModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import {
  GroupReportPointModel,
  MappedPoseidonReportDataModel,
  ReportChangeViewDayPayload,
} from '@models/ReportModel';
import { useIsFocused } from '@react-navigation/native';
import textStyles from '@styles/text';
import { fillEmptyPoints } from '@utils/fillEmptyPoints';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, FlatList, Platform, StyleSheet, View } from 'react-native';
import { SpText } from '../../../../../components/SpText/SpText';
import SpTransformStringToMultipleTexts from 'src/components/SpTransformStringToMultipleTexts';
import { SpView } from 'src/components/SpView';
import { VisitsLineChart } from 'src/components/VisitsLineChart';
import DateNavigation from 'src/pages/Dashboard/components/DateNavigation';
import { DrinkingEventReport } from 'src/pages/Dashboard/components/DrinkingEventReport';
import { TimeService } from 'src/services/TimeService';

import AvgSatatisticsContainer from './components/AvgSatatisticsContainer';
import { Header } from './components/Header';
import { LineGraphModalWrapper } from './components/LineGraphModalWrapper';
import LoadingSpinner from '../../../../../components/Loader/Loader';
import useBoundStore from '../../../../../store/store';
import DrinkingVisitsSlide from '../../../../Tour/components/DrinkingVisitsSlide/DrinkingVisitsSlide';
import { ReportRangeLastName, ReportRangeType } from '../../../constants/ReportRangeType';
import useAvarageGrouping from '../../../hooks/useAvarageGrouping';
import { useCurrentAndPreviousDays } from '../../../hooks/useCurrentAndPreviousDays';
import { useGroupedConsumptionStats } from '../../../hooks/useGroupedConsumptionStats';

interface PetDashboardVisitsProps {
  data: GroupReportPointModel<MappedPoseidonReportDataModel>[];
  pet: PetModel;
  rangeType: ReportRangeType;
  currentDate: number;
  devices: DeviceModel[];
  onGeneratePDFReport: () => void;
  onChangeViewedDays: (data: ReportChangeViewDayPayload) => void;
}

export const PetDashboardDrinking = ({
  data,
  pet,
  devices,
  onGeneratePDFReport,
  rangeType,
  onChangeViewedDays,
  currentDate,
}: PetDashboardVisitsProps) => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState(1);
  const { loading } = useBoundStore(s => s.reportStore);

  const viewedDays = useViewDays(rangeType);
  const isFocused = useIsFocused();
  const screenWidth = Dimensions.get('screen').width;
  
  const initTour = useTour({
    uniqueId: 'v0-graph-drinking-dashboard',
    components: [<DrinkingVisitsSlide />],
    devices: ['all'],
  });
  useLayoutEffect(() => {
    initTour();
  }, [isFocused]);

  const activityDetails = useCurrentAndPreviousDays<MappedPoseidonReportDataModel>(
    currentDate,
    data,
    viewedDays,
  );

  const convertedActivityDetails = useMemo(
    () => fillEmptyPoints(activityDetails.rangeData, viewedDays, currentDate),
    [activityDetails, viewedDays, currentDate],
  );
  const stats = useGroupedConsumptionStats(
    data,
    pet,
    devices,
    activityDetails?.currentDay?.date,
    true,
  );

  const [averageActivity, duration] = useAvarageGrouping(convertedActivityDetails);

  const pointDetailsRenderItem = useCallback(($event: { item: MappedPoseidonReportDataModel }) => {
    return <DrinkingEventReport data={$event.item} />;
  }, []);

  const renderItem = useCallback(
    ({
      item,
      index,
    }: {
      item: GroupReportPointModel<MappedPoseidonReportDataModel>;
      index: number;
    }) => {
      if (!item) {
        return;
      }
      const isActive = index + 1 === activeItem;
      const isVisibleLeftTitle = () => {
        if (viewedDays === ReportRangeType.SevenDays) {
          return TimeService.toLocal(item?.date).toFormat(DayWeekMonthShortFormat);
        }
        if (viewedDays === ReportRangeType.TwentyEightDays) {
          if (index === 0 || isActive || index % 7 === 0) {
            return TimeService.toLocal(item?.date).toFormat(DayWeekMonthShortFormat);
          }
          return null;
        }
      };
      return (
        <VisitsLineChart
          isActive={!!(item.points.length && isActive)}
          points={item.points}
          setActive={() => {
            item.points.length && setActiveItem(index + 1);
          }}
          lowState={viewedDays === ReportRangeType.TwentyEightDays}
          leftTitle={isVisibleLeftTitle()}
          rightTitle={`${item.points.length} ${t('visits')}`}
          pointDetailsRenderItem={pointDetailsRenderItem}
        />
      );
    },
    [activeItem, viewedDays, pointDetailsRenderItem],
  );

  const ListHeader = useCallback(
    () => (
      <View>
        {convertedActivityDetails.length > 0 &&
          convertedActivityDetails?.[0]?.date === TimeService.toLocal().endOf('day').toMillis() && (
            <SpText
              style={{ marginBottom: -20 }}
              fontFamily="Rubik_Medium">
              {t('today')}
            </SpText>
          )}
      </View>
    ),
    [convertedActivityDetails],
  );
  const dateNavigationData = {
    name: t(
      ReportRangeLastName[
        viewedDays === ReportRangeType.TwentyEightDays
          ? ReportRangeType.TwentyEightDays
          : ReportRangeType.SevenDays
      ],
      {
        value:
          viewedDays === ReportRangeType.TwentyEightDays
            ? ReportRangeType.TwentyEightDays
            : ReportRangeType.SevenDays,
      },
    ),
    viewedDays,
  };

  const titleBigTextStyles = { ...textStyles.title, ...styles.bigText };
  const titleSmallTextStyles = { ...textStyles.title, ...styles.smallText };

  return (
    <>
      {stats ? (
        <LineGraphModalWrapper
          data={stats}
          modal="LineGraph"
        />
      ) : null}
      <Header onGeneratePDFReport={onGeneratePDFReport} />
      <View style={styles.container}>
        <DateNavigation
          title={dateNavigationData.name}
          currentDate={TimeService.parseDate(currentDate).toJSDate()}
          setDate={date => {
            onChangeViewedDays({ date });
          }}
          viewedDays={viewedDays - 1}
          setViewedDays={(days: number) => {
            onChangeViewedDays({ days, date: TimeService.endOfDay().toMillis() });
            setActiveItem(days === ReportRangeType.TwentyEightDays ? null : 1);
          }}
          withButtons
        />
        {loading ? (
          <SpView style={styles.spinner}>
            <LoadingSpinner />
          </SpView>
        ) : (
          <>
            <View style={styles.grayBackground}>
              <View style={styles.whiteLayout} />
              <View style={styles.cardsContainer}>
                <AvgSatatisticsContainer
                  type="multi"
                  leftLabel={`${t('average_short')} ${t('drinks')}`}
                  rightValue={
                    <SpTransformStringToMultipleTexts
                      data={[
                        duration.timeFirst,
                        duration.unitFirst,
                        duration.timeSecond,
                        duration.unitSecond,
                      ]}
                      mainStyle={screenWidth < 420 ? titleSmallTextStyles : titleBigTextStyles}
                      prefixStyle={titleSmallTextStyles}
                    />
                  }
                  rightLabel={`${t('average_short')} ${t('duration')}`}
                  leftValue={`${averageActivity}`}
                />
              </View>
            </View>
            <SpView style={[styles.flatlistContainer, styles.containerMargin]}>
              <FlatList
                style={styles.flatlist}
                data={convertedActivityDetails}
                renderItem={renderItem}
                ListHeaderComponent={ListHeader}
                keyExtractor={item => item?.date.toString()}
                ListFooterComponent={<SpView height={Platform.OS === 'android' ? 30 : 0} />}
              />
            </SpView>
          </>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    height: '100%',
  },
  grayBackground: {
    marginLeft: -24,
    marginRight: -24,
    backgroundColor: '#f2f3f4',
  },
  whiteLayout: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: '35%',
    position: 'absolute',
  },
  flatlistContainer: {
    marginHorizontal: -24,
    paddingHorizontal: 24,
    backgroundColor: 'rgba(38, 58, 67, 0.06)',
    paddingBottom: '100%',
  },
  flatlist: {
    marginHorizontal: -24,
    paddingHorizontal: 24,
  },
  cardsContainer: {
    paddingHorizontal: 24,
    marginTop: 28,
    marginBottom: 36,
  },
  bigText: {
    fontSize: 28,
    lineHeight: 28,
    textAlign: undefined,
  },
  smallText: {
    fontSize: 16,
    textAlign: undefined,
    lineHeight: 28,
  },
  containerMargin: {
    marginBottom: 140,
  },
  spinner: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '60%',
  },
});
