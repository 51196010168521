import { Dimensions, StyleSheet, View } from 'react-native';
import React, { useMemo } from 'react';
import { FormikProps } from 'formik';
import { FieldsObj } from '@models/FormikTypes';
import SpTitle from 'src/components/SpTitle';
import { SpInput } from 'src/components/SpInput';
import { stepFormikValid } from '@utils/stepFormikValid';
import { useTranslation } from 'react-i18next';
import { emitContinueHandler } from 'src/components/StepFormCreator';

interface FormValues {
  email: string;
  retypeEmail: string;
}

interface IEmailFormProps {
  formik: FormikProps<FormValues>;
  submitErrors: FieldsObj;
  error?: string;
  setError?: (arg: any) => void;
  children?: React.ReactNode;
  fromRegister?: boolean;
  onContinuePress?: () => Promise<void>;
}

const EmailForm = ({
  formik,
  submitErrors,
  error,
  setError,
  children,
  fromRegister = false,
  onContinuePress,
}: IEmailFormProps) => {
  const { t } = useTranslation();
  const emailError = useMemo(() => {
    return stepFormikValid('email', submitErrors, formik.errors);
  }, [formik.errors, submitErrors]);

  const registerFormHeight = Dimensions.get('screen').height - 200;

  return (
    <View
      accessible={false}
      style={
        fromRegister
          ? {
              height: registerFormHeight,
              flex: 1,
              justifyContent: 'space-between',
            }
          : { height: '100%' }
      }
    >
      <View accessible={false}>
        <SpTitle text={t('register_email_title')} />
        <SpInput
          label={t('email')}
          value={formik.values?.email || ''}
          onChangeText={text => {
            setError(null);
            formik.handleChange('email')(text);
            if (text) formik.setTouched({ ...formik.touched, email: true });
          }}
          keyboardType="email-address"
          error={t(error) || t(emailError)}
          returnKeyType="done"
          onSubmitEditing={emitContinueHandler}
          multilineError
        />
      </View>
      {fromRegister && <View accessible={false}>{children}</View>}
    </View>
  );
};

export default EmailForm;
