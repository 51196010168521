import { StyleSheet, TextStyle, View } from 'react-native';
import React, { useCallback, useState } from 'react';
import { FormikProps } from 'formik';
import { SpInput } from 'src/components/SpInput';
import { SpText } from '../../../components/SpText/SpText';
import colors from '@styles/colors';
import { t } from 'i18next';
import { emitContinueHandler } from 'src/components/StepFormCreator';
import CustomKeyboardAvoidingView from 'src/components/CustomKeyboardAvoidingView';
import { testProperties } from '@utils/testProperties';

interface FormValues {
  name: string;
}

interface INameFormProps {
  formik: FormikProps<FormValues>;
  label: string;
  title: string;
  subtitle?: string;
  subtitle2?: string;
  disableKeyboardAwoiding?: boolean;
  titleStyles?: TextStyle;
  setName?: (name: string) => void;
  androidAdjustType?: 'full' | 'custom' | 'force';
  onContinuePress?: () => Promise<void>;
  /** Hide title when Name input focused.*/
  hideTitleOnFocus: boolean;
  /** Hide subtitle when Name input focused.*/
  hideSubtitleOnFocus: boolean;
  /** Hide subtitle when Name input focused.*/
  hideSubtitle2OnFocus: boolean;
}

const NameForm = ({
  formik,
  label,
  title,
  subtitle2,
  subtitle,
  disableKeyboardAwoiding = false,
  titleStyles,
  setName,
  androidAdjustType = 'force',
  onContinuePress,
  hideTitleOnFocus = true,
  hideSubtitleOnFocus = true,
  hideSubtitle2OnFocus = true,
}: INameFormProps) => {
  const [inputFocused, setInputFocused] = useState(false);
  const onInputFocus = useCallback(() => setInputFocused(true), []);
  const onInputBlur = useCallback(() => {
    setInputFocused(false);
    formik.setFieldValue('name', formik.values?.name.trim());
    formik.handleBlur('name');
  }, [formik]);
  return (
    <CustomKeyboardAvoidingView
      {...testProperties('customKeyboardAvoidingView', 'wrapper')}
      androidAdjustType={androidAdjustType}
      disabled={disableKeyboardAwoiding}
      style={{ flex: 1 }}
    >
      {(hideTitleOnFocus ? !inputFocused && !!title : !!title) && (
        <SpText
          style={[styles.title, titleStyles]}
          {...testProperties('name main title', 'text')}
        >
          {title}
        </SpText>
      )}
      {(hideSubtitleOnFocus ? !inputFocused && !!subtitle : !!subtitle) && (
        <SpText
          style={styles.subtitle}
          {...testProperties('name subtitle', 'text')}
        >
          {subtitle}
        </SpText>
      )}
      {(hideSubtitle2OnFocus ? !inputFocused && !!subtitle2 : !!subtitle2) && (
        <SpText
          style={[styles.subtitle, { marginBottom: 40 }]}
          {...testProperties('name subtitle2', 'text')}
        >
          {subtitle2}
        </SpText>
      )}
      <View style={{ paddingBottom: 30 }}>
        <SpInput
          handleBlur={onInputBlur}
          handleFocus={onInputFocus}
          value={formik.values?.name || ''}
          onChangeText={text => {
            formik.handleChange('name')(text);
            if (text) formik.setTouched({ ...formik.touched, name: true });
            if (setName) setName(text.trim());
          }}
          label={label}
          placeholder={t('type_here')}
          returnKeyType="done"
          onSubmitEditing={onContinuePress}
          error={formik.errors?.name}
        />
      </View>
    </CustomKeyboardAvoidingView>
  );
};

export default NameForm;

const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: colors.white.color,
    flex: 1,
  },
  title: {
    fontFamily: 'Rubik_SemiBold',
    fontSize: 24,
    marginBottom: 24,
    marginTop: 52,
    lineHeight: 26,
    color: colors.greyText.color,
  },
  subtitle: {
    fontFamily: 'Rubik',
    color: colors.black.color,
    marginBottom: 24,
    fontSize: 16,
    lineHeight: 30,
    width: '90%',
  },
});
