import { ConsumptionAlertOutcome, ConsumptionInsightOutcome } from '@models/ConsumptionInsight';
import i18n from '@utils/i18n';

export interface ConsumptionInsightText {
  title: string;
  titlePast?: string;
  description?: string;
  warning?: string;
}

export const ConsumptionInsightAlertBottomSheetText: Record<
  ConsumptionAlertOutcome,
  ConsumptionInsightText
> = {
  [ConsumptionAlertOutcome.Unavailable]: {
    title: null,
    titlePast: null,
    description: null,
  },
  [ConsumptionAlertOutcome.Ok]: {
    title: null,
    titlePast: null,
    description: null,
  },
  [ConsumptionAlertOutcome.Problematic]: {
    title: i18n.t('ci_alert_popup_problematic_title'),
    titlePast: i18n.t('ci_alert_popup_problematic_title_past'),
    warning: i18n.t('ci_alert_popup_problematic_warning'),
    description: i18n.t('ci_alert_popup_problematic_description'),
  },
  [ConsumptionAlertOutcome.StillProblematic]: {
    title: i18n.t('ci_alert_popup_still_problematic_title'),
    titlePast: i18n.t('ci_alert_popup_still_problematic_title_past'),
    warning: i18n.t('ci_alert_popup_still_problematic_warning'),
    description: i18n.t('ci_alert_popup_still_problematic_description'),
  },
};

export const ConsumptionInsightHabitBottomSheetText: Record<
  ConsumptionInsightOutcome,
  ConsumptionInsightText
> = {
  [ConsumptionInsightOutcome.Elevated]: {
    title: i18n.t('ci_habit_popup_elevated_title'),
    titlePast: i18n.t('ci_habit_popup_elevated_title_past'),
    warning: i18n.t('ci_habit_popup_elevated_warning'),
    description: i18n.t('ci_habit_popup_elevated_description'),
  },
  [ConsumptionInsightOutcome.Decreased]: {
    title: i18n.t('ci_habit_popup_decreased_title'),
    titlePast: i18n.t('ci_habit_popup_decreased_title_past'),
    warning: i18n.t('ci_habit_popup_decreased_warning'),
    description: i18n.t('ci_habit_popup_decreased_description'),
  },
  [ConsumptionInsightOutcome.Ok]: {
    title: i18n.t('ci_habit_popup_normal_title'),
  },
  [ConsumptionInsightOutcome.WasDecreased]: {
    title: i18n.t('ci_habit_popup_was_decreased_title'),
    titlePast: i18n.t('ci_habit_popup_was_decreased_title_past'),
    warning: i18n.t('ci_habit_popup_was_decreased_warning'),
    description: i18n.t('ci_habit_popup_was_decreased_description'),
  },
  [ConsumptionInsightOutcome.WasElevated]: {
    title: i18n.t('ci_habit_popup_was_elevated_title'),
    titlePast: i18n.t('ci_habit_popup_was_elevated_title_past'),
    warning: i18n.t('ci_habit_popup_was_elevated_warning'),
    description: i18n.t('ci_habit_popup_was_elevated_description'),
  },
  [ConsumptionInsightOutcome.WasFluctuating]: {
    title: i18n.t('ci_habit_popup_was_fluctuating_title'),
    titlePast: i18n.t('ci_habit_popup_was_fluctuating_title_past'),
    warning: i18n.t('ci_habit_popup_was_fluctuating_warning'),
    description: i18n.t('ci_habit_popup_was_fluctuating_title'),
  },
  [ConsumptionInsightOutcome.WillBeAvailable]: {
    title: i18n.t('ci_habit_popup_will_available_title'),
    description: i18n.t('ci_habit_popup_will_available_description'),
  },
  [ConsumptionInsightOutcome.Unavailable]: {
    title: i18n.t('ci_habit_popup_unavailable_title'),
  },
};
